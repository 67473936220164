import React from "react";
import { graphql } from "gatsby";
import { getImage, StaticImage } from "gatsby-plugin-image";

import {
  Layout,
  LinkButton,
  FeatureGridWithOffsetIcons,
  CTAAreYouReady,
  ContractingProcess,
  SEO,
} from "../../components";

import {
  ComputerDesktopIcon,
  CogIcon,
  ShieldCheckIcon,
  HeartIcon,
  BanknotesIcon,
  CalculatorIcon,
  DocumentTextIcon,
  GlobeAmericasIcon,
} from "@heroicons/react/24/outline";

const benefits = [
  {
    name: "Garantía",
    description:
      "Te ofrecemos la garantía de producción más competitiva del mercado; tu sistema siempre funcionará a su máximo rendimiento.",
    icon: ShieldCheckIcon,
  },
  {
    name: "Monitoreo",
    description:
      "Supervisamos 24/7 que el sistema solar esté funcionando y dándote los resultados que mereces.",
    icon: ComputerDesktopIcon,
  },
  {
    name: "Seguro $1M",
    description:
      "Seguro contra daños; tenemos el seguro más alto del mercado de $1,000,000 USD.",
    icon: HeartIcon,
  },
  {
    name: "Mantenimiento",
    description:
      "Nos aliamos con las mejores cuadrillas de instaladores en cada ciudad para asegurar que tu sistema esté en óptimas condiciones, ofreciendo servicios preventivos y correctivos.",
    icon: CogIcon,
  },
];

const businessBenefits = [
  {
    title: "Estabiliza tu gasto de luz",
    icon: BanknotesIcon,
  },
  {
    title: "Obtén beneficios fiscales",
    icon: CalculatorIcon,
  },
  {
    title: "Sé dueño del activo",
    icon: DocumentTextIcon,
  },
  {
    title: "Ayuda al medio ambiente y mejora tu imagen",
    icon: GlobeAmericasIcon,
  },
];

const BusinessPage = ({ data }) => {
  const brightSteps = getImage(data.brightSteps.childImageSharp);

  return (
    <Layout>
      <SEO
        title="Energía solar para tu negocio"
        description="Bright Negocio es un servicio premium de energía para tu empresa en México."
      />
      <div className="py-16 lg:py-24">
        <div className="mx-auto px-8 max-w-2xl md:max-w-3xl lg:max-w-5xl xl:max-w-7xl">
          <div className="text-center">
            <h1 className="tracking-tight font-bold text-blue-500 text-4xl md:text-5xl">
              Energía solar para tu negocio
            </h1>

            <p className="mt-3 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
              Haz que tu pago de luz no sea un gasto… sino una inversión.
            </p>
            <div className="mx-auto mt-6 max-w-xs w-56">
              <LinkButton arrow shadow size="large" to="/cotizar">
                Cotizar
              </LinkButton>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-gray-50 py-16 lg:py-24">
        <div className="flex flex-col mx-auto justify-center lg:justify-between max-w-2xl md:max-w-3xl lg:max-w-5xl xl:max-w-7xl px-8 md:px-0">
          <h2 className="mt-2 text-3xl font-bold text-gray-900 tracking-tight sm:text-4xl  text-center">
            Adquiere un activo para tu negocio
          </h2>
          <div className="flex flex-col lg:flex-row justify-center lg:justify-between mt-7 lg:mt-16">
            <div className="flex flex-col mx-auto w-full lg:w-1/2 items-center px-7 text-center lg:text-left max-w-lg">
              <h1 className="mt-8 md:mt-5 text-2xl lg:text-3xl text-gray-700 font-medium">
                El gasto de luz se convierte en una inversión.
              </h1>
              <p className="mt-5 md:mt-5 text-base md:text-xl lg:text-lg xl:text-xl text-gray-500">
                Por el mismo monto que se paga a CFE, Bright incluye el concepto
                del gasto de luz y un activo que ofrece ganancias a mediano y
                largo plazo.
              </p>
            </div>
            <div className="flex w-full lg:w-1/2 grid grid-cols-1 mt-16 lg:mt-0">
              <ul
                role="list"
                className="space-y-4 sm:grid sm:grid-cols-2 sm:gap-4 sm:space-y-0 lg:gap-x-4"
              >
                {businessBenefits.map((item, index) => (
                  <li
                    key={index}
                    className="bg-white px-6 py-5 rounded-lg border border-gray-100 shadow-md"
                  >
                    <div className="flex items-center">
                      <div className="inline-flex items-center justify-center p-3 bg-yellow-500 rounded-full">
                        <item.icon className="h-8 w-8 text-white" />
                      </div>
                      <div className="font-medium text-md leading-6 ml-3">
                        <h3>{item.title}</h3>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="py-16 lg:py-24 bg-white">
        <div className="flex flex-col lg:flex-row mx-auto justify-center lg:justify-between max-w-2xl md:max-w-3xl lg:max-w-5xl xl:max-w-7xl">
          <div className="flex w-full lg:w-1/2 justify-center items-center px-8 text-center lg:text-left">
            <div>
              <h1 className="mt-8 md:mt-5 text-4xl lg:text-4xl text-gray-700 font-bold">
                Haz crecer tu negocio de forma sostenible
              </h1>

              <p className="mt-5 md:mt-5 text-base md:text-xl lg:text-lg xl:text-xl text-gray-500">
                Además de ahorrar en tu pago de luz, utilizar energía solar en
                tu negocio genera un impacto positivo en el medio ambiente a
                través de la reducción de emisiones de CO2 que son nocivas para
                el planeta y tu salud.
              </p>
            </div>
          </div>
          <div className="flex w-full lg:w-1/2 items-center justify-center lg:justify-end px-8 mt-6 lg:mt-0">
            <StaticImage
              alt="Dos persona con paneles solares y una bombilla"
              height={450}
              src="../../images/illustrations/concept_light_bulb_with_panels.png"
            />
          </div>
        </div>
      </div>
      <ContractingProcess image={brightSteps} />
      <FeatureGridWithOffsetIcons
        title="Beneficios de suscribirte con Bright"
        features={benefits}
      />
      <CTAAreYouReady />
    </Layout>
  );
};
export default BusinessPage;

export const query = graphql`
  query {
    brightSteps: file(
      relativePath: { eq: "illustrations/4_steps_bright.png" }
    ) {
      childImageSharp {
        gatsbyImageData(height: 700)
      }
    }
  }
`;
